import {Accordion, Col, Container, Row} from "react-bootstrap";

export default function Help() {
  return (
    <Container className="mt-4">
      <Row>
        <Col></Col>
        <Col className="col-sm-6">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>What is a station?</Accordion.Header>
              <Accordion.Body>
                A station is a place that receives item requests. It can be a kitchen, a bar, etc.
                For example, a restaurant has a meats kitchen, a sushi kitchen and a bar with drinks.
                This restaurant will have 3 stations - "Meats kitchen" (requests of items in the "Meats" category get sent here),
                "Sushi kitchen" (requests of items in the "Sushi" category get sent here) and "Bar" (requests of items in the "Beverages" category get sent here).
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  )
}