import React, {useEffect, useState} from "react";
import {Button, Container, Form, Modal} from "react-bootstrap";
import axios from "axios";
import CreateRestaurantForm from "./CreateRestaurantForm";
import "ka-table/style.css";
import {kaReducer, Table} from "ka-table";
import {ActionType, DataType, SortingMode} from "ka-table/enums";
import {loadData, updateData} from "ka-table/actionCreators";
import {BiPlusMedical} from "react-icons/bi";
import {FaTrash, FaWrench} from "react-icons/fa";
import Cookies from "universal-cookie";
import QRCode from "react-qr-code";

const cookies = new Cookies();
const token = cookies.get("TOKEN");

export default function Restaurants() {
  const [restaurants, setRestaurants] = useState([]);

  const [showNewRestaurantModal, setShowNewRestaurantModal] = useState(false);

  const handleCloseNewRestaurantModal = () => setShowNewRestaurantModal(false);
  const handleShowNewRestaurantModal = () => setShowNewRestaurantModal(true);

  const tablePropsInit = {
    columns: [
      {key: 'name', title: 'Name', dataType: DataType.String},
      {key: 'actions', title: 'Actions', dataType: DataType.String},
    ],
    rowKeyField: 'id',
    sortingMode: SortingMode.Single,
    singleAction: loadData(),
  };

  const [tableProps, changeTableProps] = useState(tablePropsInit);
  const dispatch = async (action) => {
    changeTableProps((prevState) => kaReducer(prevState, action));

    if (action.type === ActionType.LoadData) {
      axios({
        method: "get",
        url: "https://neomenu-backend.fly.dev/restaurants",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          let restaurants = res.data.data;
          setRestaurants(restaurants);
          dispatch(updateData(restaurants));
        })
        .catch(e => console.log(JSON.stringify(e)))
    }
  };

  const deleteRestaurant = (restaurantId) => {
    if (!window.confirm("Deleting this restaurant will delete all its categories and items. Are you sure you want to proceed?")) {
      return;
    }
    axios({
      method: "delete",
      url: "https://neomenu-backend.fly.dev/restaurant",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: restaurantId
      }
    })
      .then(async res => {
        let remainingRestaurants = restaurants.filter(r => r._id !== restaurantId);
        setRestaurants(remainingRestaurants);
        dispatch(updateData(remainingRestaurants));
      })
      .catch(e => console.log(JSON.stringify(e)))
  }

  let addRestaurant = (restaurant) => {
    let newRestaurants = restaurants;
    newRestaurants.push(restaurant);
    setRestaurants(newRestaurants);
    dispatch(updateData(newRestaurants));
  };

  const [showModal, setShowModal] = useState(false);
  const [restaurantId, setRestaurantId] = useState(null);

  const handleShowLiveMenuModal = (restaurantId) => {
    setRestaurantId(restaurantId);
    setShowModal(true);
  }

  const handleCloseLiveMenuModal = () => {
    setShowModal(false);
  }

  return (
    <Container className="pt-4">

      <div className="d-flex mb-2">
        <div className="me-auto" style={{display: "flex"}}>
          <h3 className="m-0" style={{display: "inline-block", alignSelf: "flex-end"}}>Restaurants</h3>
        </div>
        <div>
          <Button variant={"success"} onClick={handleShowNewRestaurantModal}><BiPlusMedical/> New restaurant</Button>
        </div>
      </div>
      <Table
        {...tableProps}
        childComponents={
          {
            cellText: {
              content: (props) => {
                switch (props.column.key) {
                  case 'actions':
                    return <>
                      <a href={`/restaurants/${props.rowData._id}/manage`}>
                        <button className="btn btn-sm btn-primary"><FaWrench/></button>
                      </a>
                      {' '}
                      <button className="btn btn-sm btn-danger" onClick={() => deleteRestaurant(props.rowData._id)}>
                        <FaTrash/></button>
                      {' '}
                      {/*<a href={`https://menu.neo.menu/g/${props.rowData._id}`} target="_blank">
                      <button className="btn btn-sm btn-primary">View live menu</button></a>*/}
                      <button className="btn btn-sm btn-primary" onClick={() => handleShowLiveMenuModal(props.rowData._id)}>View live menu</button>
                    </>
                }
              }
            }
          }
        }
        dispatch={dispatch}
      />

      <CreateRestaurantForm show={showNewRestaurantModal} handleClose={handleCloseNewRestaurantModal}
                            addRestaurant={addRestaurant}/>

      <Modal show={showModal} onHide={handleCloseLiveMenuModal}>
        <Modal.Header closeButton>
          <Modal.Title>View menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Scan this code with your phone to see the live menu:</p>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "200px", width: "200px" }}
            value={`https://menu.neo.menu/g/${restaurantId}`}
            viewBox={`0 0 256 256`}
          />
          <p className="mt-4">Or <a href={`https://menu.neo.menu/g/${restaurantId}`} target="_blank">click here</a> to see it on your browser (the mobile view might look too large on desktop).</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseLiveMenuModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
