import React, {useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import {errorToast, successToast} from "./shared/toast/toast-generator";

const cookies = new Cookies();
const token = cookies.get("TOKEN");

export default function CreateTableForm(props) {
  const [name, setName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const configuration = {
      method: "post",
      url: `https://neomenu-backend.fly.dev/restaurants/${props.restaurantId}/tables`,
      data: {
        name,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(configuration)
      .then((result) => {
        props.addTable(result.data.data);
        successToast(result.data.message);
      })
      .catch((error) => {
        errorToast(error.response.data.message);
      });
  };

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>New table</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
          </Form.Group>


        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={props.handleClose}>
          Cancel
        </Button>
        <Button
          variant="success"
          type="submit"
          onClick={(e) => {
            handleSubmit(e);
            props.handleClose();
          }}
        >
          Create table
        </Button>
      </Modal.Footer>
    </Modal>

  );
}
