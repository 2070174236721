import React, { useEffect, useState } from "react";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";

export default function Account(props) {

  let dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };

  const [memberships, setMemberships] = useState([]);

  const cookies = new Cookies();
  const token = cookies.get("TOKEN");

  const getMemberships = () => {
    axios({
      method: "get",
      url: "https://neomenu-backend.fly.dev/memberships",
    })
      .then(res => {
        let memberships = res.data.data;
        memberships.sort((a, b) => a.level - b.level);
        memberships = memberships.filter(m => m.level > 0);
        setMemberships(memberships);
      })
      .catch(e => console.log(JSON.stringify(e)))
  }

  const subscribe = (membershipId) => {
    axios({
      method: "post",
      url: "https://neomenu-backend.fly.dev/subscribe",
      data: {
        membershipId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => {
        window.location.replace(res.data.data)
      })
      .catch(e => console.log(JSON.stringify(e)))
  }

  useEffect(() => {
    getMemberships();
  }, [])

  if (!props.user || !memberships) return null;

  let userMembership = memberships.find(m => m.level === props.user.membership);

  if (!userMembership) return null;

  return (
    <Container className="mt-4">
      <div className="d-flex mb-2">
        <div className="me-auto"  style={{display: "flex"}}>
          <h3 className="m-0" style={{display: "inline-block", alignSelf: "flex-end"}}>Account</h3>
        </div>
      </div>

      <span><b>Plan</b>: {userMembership.name}</span>
      <br />
      <span><b>Ends</b>: {new Date(props.user.membershipEnd).toLocaleDateString("en-US", dateOptions)}</span>
      <Row className="mt-3">
        {
          memberships.map(m => {
            return <Col className="mb-4">
              <Card style={ m.level === userMembership.level ? {width: '20rem', border: "2px solid #1ea59a"} : { width: '20rem' }}>
                <Card.Body>
                  <Card.Title>{m.name}</Card.Title>
                  <Card.Text>
                    <ul>
                      <li><b>{m.restaurantLimit}</b> Restaurant{m.restaurantLimit > 1 ? 's' : ''}</li>
                      <li>For each restaurant:
                        <ul>
                          <li><b>{m.stationLimit}</b>  Station{m.stationLimit > 1 ? 's' : ''}</li>
                          <li><b>{m.waiterLimit}</b>  Waiter{m.waiterLimit > 1 ? 's' : ''}</li>
                          <li><b>{m.tableLimit}</b>  Table{m.tableLimit > 1 ? 's' : ''}</li>
                          <li><b>{m.itemLimit}</b>  Item{m.itemLimit > 1 ? 's' : ''}</li>
                        </ul>
                      </li>
                    </ul>
                  </Card.Text>
                  {m.level === userMembership.level ?
                    <Button disabled={m.level === 0} variant={"success"} className="w-100" onClick={() => {subscribe(m._id)}}>Extend for <b>${m.price}.00 USD</b>/mo</Button>
                    :
                    <Button disabled={m.level === 0} variant={"success"} className="w-100" onClick={() => {subscribe(m._id)}}>Subscribe for <b>${m.price}.00 USD</b>/mo</Button>
                  }
                </Card.Body>
              </Card>
            </Col>
          })
        }
      </Row>
    </Container>
  );
}
