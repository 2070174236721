import {Switch, Redirect} from "react-router-dom";
import {Container, Navbar, Nav} from "react-bootstrap";
import Restaurants from "./Restaurants";
import ProtectedRoutes from "./ProtectedRoutes";
import Login from "./Login";
import Register from "./Register";
import ManageItems from "./ManageItems";
import Cookies from "universal-cookie";
import logo from '../src/img/logo.png'
import "./App.css"
import Account from "./Account";
import {useEffect, useState} from "react";
import axios from "axios";
import PaymentSuccessful from "./PaymentSuccessful";
import PaymentCanceled from "./PaymentCanceled";
import Help from "./Help";
import GuestRoute from "./GuestRoute";

const cookies = new Cookies();

function App() {
  const logout = () => {
    cookies.remove("TOKEN", {path: "/"});
    window.location.href = "/";
  }

  const token = cookies.get("TOKEN");

  const [user, setUser] = useState(null);

  const getUser = () => {
    axios({
      method: "GET",
      url: "https://neomenu-backend.fly.dev/account",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => {
        let user = res.data.data;
        setUser(user);
      })
      .catch(e => console.log(JSON.stringify(e)))
  }

  useEffect(() => {
    getUser();
  }, [])

  return (
    <>
      {token ?
        <>
          <Navbar style={{background: "white"}}>
            <Container>
              <Navbar.Brand href="/">
                <img src={logo} alt="logo" height={22}/>
              </Navbar.Brand>
              <Navbar.Collapse>
                <Nav className="ms-auto">
                  <Nav.Link href="/account">Account</Nav.Link>
                  <Nav.Link href="/restaurants">Restaurants</Nav.Link>
                  <Nav.Link href="/help">Help</Nav.Link>
                  <div onClick={() => logout()}><Nav.Link>Logout</Nav.Link></div>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </>
        :
        <Navbar style={{background: "white"}}>
          <Container>
            <Navbar.Brand className="mx-auto" href="/">
              <img src={logo} alt="logo" height={22}/>
            </Navbar.Brand>
          </Container>
        </Navbar>
      }
      <div className="nav-separator"></div>
      <Switch>
        <GuestRoute exact path="/login" component={Login}/>
        <GuestRoute exact path="/register" component={Register}/>
        <ProtectedRoutes exact path="/account" component={Account} user={user} />
        <ProtectedRoutes path="/restaurants/:id/manage" component={ManageItems}/>
        <ProtectedRoutes path="/restaurants" component={Restaurants}/>
        <ProtectedRoutes path="/help" component={Help}/>
        <ProtectedRoutes path="/account/subscription_success" component={PaymentSuccessful}/>
        <ProtectedRoutes path="/account/subscription_canceled" component={PaymentCanceled}/>
        <Redirect from="/" to="/login"/>
      </Switch>
    </>
  );
}

export default App;
