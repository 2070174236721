import {Bounce, toast} from "react-toastify";

export const successToast = (message) => {
  toast.success(message, {
    style: {fontWeight: "bold"},
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  });
}

export const errorToast = (message) => {
  toast.error(message, {
    style: {fontWeight: "bold"},
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  });
}