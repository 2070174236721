import React, {useEffect, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import {useDropzone} from "react-dropzone";
import Compress from 'compress.js';
import {errorToast, successToast} from "./shared/toast/toast-generator";

const compress = new Compress();

const cookies = new Cookies();
const token = cookies.get("TOKEN");

const MAX_IMAGE_SIZE_IN_MB = 8;

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

export default function CreateItemForm(props) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [categoryId, setCategoryId] = useState(null);
  const [price, setPrice] = useState();

  useEffect(() => {
    if (props.categories.length > 0) {
      setCategoryId(props.categories[0]._id);
    }
  }, [props.categories]);

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const [files, setFiles] = useState([]);
  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': []
    },
    maxFiles: 1,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          alt="img"
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview)
          }}
        />
      </div>
    </div>
  ));

  const handleSubmit = async (e) => {
    e.preventDefault();
    let image = null;

    if (files[0]) {
      image = await toBase64(files[0]);
      let megabyteSize = files[0].size / (1024 * 1024);

      if (megabyteSize > MAX_IMAGE_SIZE_IN_MB) {
        compress
          .compress(files, {
            size: MAX_IMAGE_SIZE_IN_MB,
            quality: 1,
            resize: true,
            rotate: false,
          })
          .then((data) => {
            image = data[0].data;
            createItem(props.restaurantId, categoryId, {name, description, price, image});
          });
      } else {
        createItem(props.restaurantId, categoryId, {name, description, price, image});
      }
    } else {
      createItem(props.restaurantId, categoryId, {name, description, price});
    }
  };

  const createItem = (restaurantId, categoryId, item) => {
    const configuration = {
      method: "post",
      url: `https://neomenu-backend.fly.dev/restaurants/${restaurantId}/categories/${categoryId}/items`,
      data: item,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(configuration)
      .then((result) => {
        props.addItem(result.data.data);
        successToast(result.data.message);
      })
      .catch((error) => {
        errorToast(error.response.data.message);
      });
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  const resetForm = () => {
    setName("");
    setDescription("");
    setCategoryId(null);
    setPrice(null);
    setFiles([]);
  };

  const handleClose = () => {
    resetForm();
    props.handleClose();
  };

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>New item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              className="mb-2"
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
            <Form.Label>Description (optional)</Form.Label>
            <Form.Control
              as="textarea"
              className="mb-2"
              type="text"
              rows={3}
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Name"
            />
            <Form.Label>Category</Form.Label>
            <Form.Control className="mb-2" as="select" onChange={(e) => setCategoryId(e.target.value)}>
              {props.categories.map(c => <option key={c._id} value={c._id}>{c.name}</option>)}
            </Form.Control>
            <Form.Label>Price</Form.Label>
            <Form.Control
              className="mb-2"
              type="number"
              name="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              placeholder="Price"
            />
            <Form.Label>Image (optional)</Form.Label>
            <div className="align-items-center" style={{
              cursor: "pointer",
              border: "2px dashed lightgrey",
              borderRadius: "5px",
              color: "#bdbdbd",
              background: "#fafafa"
            }} {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <span style={{lineHeight: "50px", display: "table", margin: "0 auto"}}>Drag and drop or browse for an image</span>
            </div>
            <aside style={thumbsContainer}>
              {thumbs}
            </aside>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="success"
          type="submit"
          onClick={(e) => {
            handleSubmit(e);
            handleClose();
          }}
        >
          Create item
        </Button>
      </Modal.Footer>
    </Modal>

  );
}
