import {Button} from "react-bootstrap";
import {BiPlusMedical} from "react-icons/bi";
import {FaTrash} from "react-icons/fa";
import {Table} from "ka-table";
import React from "react";

export default function StationsTable(funcProps) {
  const renderTable = () => {
    if (funcProps.loading) {
      return <div className="w-100 text-center">Loading restaurant data...</div>;
    }

    if (funcProps.disabled) {
      return <div className="text-center">You need to create at least one category before adding terminals.</div>;
    }
    if (funcProps.length === 0) {
      return <div className="text-center">No stations.</div>;
    }
    return <Table
      {...funcProps.tableProps}
      childComponents={{
        cellText: {
          content: (props) => {
            switch (props.column.key) {
              case 'name':
                return <>
                  <span>{props.rowData.name}</span>{' '}
                  {props.rowData.categoriesIds.map(cId => {
                    return <small key={cId} className="fw-bold ms-1"
                                  style={{padding: "0px 4px", background: "#1ea59a", color: "white"}}>{
                      funcProps.categories.find(c => c._id === cId).name
                    }</small>
                  })}
                </>
              case 'actions':
                return <button className="btn btn-sm btn-light ml-1"
                               onClick={() => funcProps.deleteTerminal(props.rowData._id)}>
                  <FaTrash/>
                </button>
            }
          }
        }
      }}
      dispatch={funcProps.dispatch}
    />
  }

  return (
    <>
      <div className="d-flex mb-2">
        <div className="me-auto" style={{display: "flex"}}>
          <h3 className="m-0" style={{display: "inline-block", alignSelf: "flex-end"}}>{funcProps.dropdownButton}</h3>
        </div>
        <div>
          <Button variant={"success"} disabled={funcProps.disabled}
                  onClick={funcProps.showCreateModal}><BiPlusMedical/> New station</Button>
        </div>
      </div>
      {renderTable()}
    </>
  )
}