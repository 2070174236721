import {Dropdown} from "react-bootstrap";

export default function DropdownButton(props) {
  if (!props.options) return null;
  return <Dropdown>
    <Dropdown.Toggle variant="success" id="dropdown-basic">
      {props.currentOption}
    </Dropdown.Toggle>

    <Dropdown.Menu>
      {props.options.map(o => {
        return <Dropdown.Item key={o} onClick={() => props.setCurrentOption(o)}>{o}</Dropdown.Item>
      })}
    </Dropdown.Menu>
  </Dropdown>
}