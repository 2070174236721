import {useEffect} from "react";
import {useHistory} from "react-router-dom";

export default function PaymentSuccessful() {

  const history = useHistory();

  useEffect(() => {
    setTimeout(function() {
      history.push('/account');
    }, 2000);
  }, [])

  return(
    <div className="w-100 text-center">
      <p>Redirecting you...</p>
    </div>
  )
}