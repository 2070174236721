import React, { useState } from "react";
import {Form, Button, Container, Row, Col} from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default function Login() {
  // initial state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login, setLogin] = useState(false);

  const handleSubmit = (e) => {
    // prevent the form from refreshing the whole page
    e.preventDefault();

    // set configurations
    const configuration = {
      method: "post",
      url: "https://neomenu-backend.fly.dev/login",
      data: {
        email,
        password,
      },
    };

    // make the API call
    axios(configuration)
      .then((result) => {
        // set the cookie
        cookies.set("TOKEN", result.data.token, {
          path: "/",
        });
        // redirect user to the auth page
        window.location.href = "/restaurants";

        setLogin(true);
      })
      .catch((error) => {
        if (!error || !error.response) {
          alert("Server error");
          return;
        }
        if (error.response.status === 400) {
          alert("Invalid email or password");
          return;
        }
        else if (error.response.status === 404) {
          alert("User not found");
        }
        else {
          alert("Server error");
        }
      });
  };

  return (
    <Container className="pt-4">
      <Row>
        <Col></Col>
        <Col style={{minWidth: "400px", maxWidth: "500px"}}>
          <div className="p-5" style={{boxShadow: "0px 5px 30px -10px rgb(0 0 0 / 10%)"}}>
            <h2>Login</h2>
            <Form onSubmit={(e) => handleSubmit(e)}>
            <Form.Group>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email"
              />
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
            </Form.Group>

            <Button
              className="mt-4 mb-2 w-100"
              variant="success"
              type="submit"
              onClick={(e) => handleSubmit(e)}
            >
              Login
            </Button>
              <small>Don't have an account? <a href="/register" style={{color: "#1ea59a"}}>Sign up.</a></small>
          </Form>
          </div>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
}
