import {Badge, Button} from "react-bootstrap";
import {BiPlusMedical} from "react-icons/bi";
import {FaTrash} from "react-icons/fa";
import {Table} from "ka-table";
import React from "react";

export default function WaitersTable(funcProps) {

  const renderTable = () => {
    if (funcProps.loading) {
      return <div className="w-100 text-center">Loading restaurant data...</div>;
    }

    if (funcProps.length === 0) {
      return <div className="text-center">No waiters.</div>;
    }
    return <Table
      {...funcProps.tableProps}
      childComponents={{
        cellText: {
          content: (props) => {
            switch (props.column.key) {
              case 'actions':
                return <button className="btn btn-sm btn-light ml-1"
                               onClick={() => funcProps.deleteWaiter(props.rowData._id)}>
                  <FaTrash/>
                </button>
            }
          }
        }
      }}
      dispatch={funcProps.dispatch}
    />
  }

  return (
    <>
      <div className="d-flex mb-2">
        <div className="me-auto" style={{display: "flex"}}>
          <h3 className="m-0" style={{display: "inline-block", alignSelf: "flex-end"}}>{funcProps.dropdownButton}</h3>
        </div>
        <div>
          <Button variant={"success"} onClick={funcProps.showCreateModal}><BiPlusMedical/> New waiter</Button>
        </div>
      </div>
      {renderTable()}
    </>
  )
}