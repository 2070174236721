import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default function GuestRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        const token = cookies.get("TOKEN");

        // If there is no token, allow access to the component
        if (!token) {
          return <Component {...rest} />;
        } else {
          // If there is a token, redirect the user to a different page (e.g., home)
          return (
            <Redirect
              to={{
                pathname: "/restaurants",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
}
