import React, {useEffect, useState} from "react";
import Cookies from "universal-cookie";
import "ka-table/style.css";
import {useParams} from "react-router-dom";
import axios from "axios";
import CreateCategoryForm from "./CreateCategoryForm";
import {DataType, PagingPosition, SortingMode} from "ka-table/enums";
import {loadData, updateData} from "ka-table/actionCreators";
import {kaReducer} from "ka-table";
import CreateItemForm from "./CreateItemForm";
import {Col, Container, Row} from "react-bootstrap";
import CreateWaiterForm from "./CreateWaiterForm";
import CreateTableForm from "./CreateTableForm";
import CreateStationForm from "./CreateStationForm";
import ItemsTable from "./tables/ItemsTable";
import CategoriesTable from "./tables/CategoriesTable";
import TablesTable from "./tables/TablesTable";
import StationsTable from "./tables/StationsTable";
import WaitersTable from "./tables/WaitersTable";
import DropdownButton from "./tables/DropdownButton";

const cookies = new Cookies();
const token = cookies.get("TOKEN");

export default function ManageItems() {

  const [currentRestaurant, setCurrentRestaurant] = useState({});
  const [categories, setCategories] = useState([]);
  const [waiters, setWaiters] = useState([]);
  const [tables, setTables] = useState([]);
  const [stations, setStations] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showNewCategoryModal, setShowNewCategoryModal] = useState(false);
  const [showNewItemModal, setShowNewItemModal] = useState(false);
  const [showNewWaiterModal, setShowNewWaiterModal] = useState(false);
  const [showNewTerminalModal, setShowNewTerminalModal] = useState(false);
  const [showNewTableModal, setShowNewTableModal] = useState(false);

  const handleCloseNewCategoryModal = () => setShowNewCategoryModal(false);
  const handleShowNewCategoryModal = () => setShowNewCategoryModal(true);
  const handleCloseNewItemModal = () => setShowNewItemModal(false);
  const handleShowNewItemModal = () => setShowNewItemModal(true);
  const handleCloseNewWaiterModal = () => setShowNewWaiterModal(false);
  const handleShowNewWaiterModal = () => setShowNewWaiterModal(true);
  const handleCloseNewTerminalModal = () => setShowNewTerminalModal(false);
  const handleShowNewTerminalModal = () => setShowNewTerminalModal(true);
  const handleCloseNewTableModal = () => setShowNewTableModal(false);
  const handleShowNewTableModal = () => setShowNewTableModal(true);

  const addCategory = async (category) => {
    let newData = [...categories];
    newData.push(category);
    setCategories(newData);
    await categoriesDispatch(updateData(newData))
  }

  const addStation = async (station) => {
    let newData = [...stations];
    newData.push(station);
    setStations(newData);
    await stationsDispatch(updateData(newData));
  }

  const addWaiter = async (waiter) => {
    let newData = [...waiters];
    newData.push(waiter);
    setWaiters(newData);
    await waitersDispatch(updateData(newData));
  }

  const addTable = async (table) => {
    let newData = [...tables];
    newData.push(table);
    setTables(newData);
    await tablesDispatch(updateData(newData));
  }

  const addItem = async (item) => {
    let newData = [...items];
    let category = categories.find(c => c._id === item.categoryId);
    item.categoryName = category.name;
    newData.push(item);
    setItems(newData);
    await itemsDispatch(updateData(newData));
  }

  const getLoggedInUserRestaurants = () => {
    return axios({
      method: "get", url: "https://neomenu-backend.fly.dev/restaurants", headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  const getRestaurantCategories = (restaurantId) => {
    return axios({
      method: "get", url: `https://neomenu-backend.fly.dev/restaurants/${restaurantId}/categories`, headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  const getRestaurantWaiters = (restaurantId) => {
    return axios({
      method: "get", url: `https://neomenu-backend.fly.dev/restaurants/${restaurantId}/waiters`, headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  const getRestaurantTerminals = (restaurantId) => {
    return axios({
      method: "get", url: `https://neomenu-backend.fly.dev/restaurants/${restaurantId}/stations`, headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  const getRestaurantTables = (restaurantId) => {
    return axios({
      method: "get", url: `https://neomenu-backend.fly.dev/restaurants/${restaurantId}/tables`, headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  const getCategoryItems = (restaurantId, categoryId) => {
    return axios({
      method: "get",
      url: `https://neomenu-backend.fly.dev/restaurants/${restaurantId}/categories/${categoryId}/items`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  const logout = () => {
    cookies.remove("TOKEN", {path: "/"});
    window.location.href = "/";
  }

  const {id: restaurantId} = useParams();

  const itemsTablePropsInit = {
    columns: [{
      key: 'image', title: '', width: 100
    }, {key: 'name', title: 'Name', dataType: DataType.String}, {
      key: 'price', title: 'Price', dataType: DataType.Number
    }, {key: 'categoryName', title: 'Category', dataType: DataType.String}, {
      key: 'actions', title: 'Actions', dataType: DataType.String,
    },],
    rowKeyField: '_id',
    sortingMode: SortingMode.Single,
    singleAction: loadData(),
    groups: [{columnKey: 'categoryName'}],
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: 25,
      pageSizes: [25, 50, 100],
      position: PagingPosition.Bottom
    },
    pageButtonProps: {
      style: {
        background: "green"
      }
    }
  };

  const categoriesTablePropsInit = {
    columns: [{key: 'name', title: 'Category', dataType: DataType.String}, {
      key: 'actions', title: 'Actions', dataType: DataType.String
    },],
    rowKeyField: '_id',
    sortingMode: SortingMode.Single,
    singleAction: loadData(),
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: 10,
      pageSizes: [5, 10, 15],
      position: PagingPosition.Bottom
    },
  };

  const tablesTablePropsInit = {
    columns: [{key: 'name', title: 'Name', dataType: DataType.String}, {
      key: 'actions', title: 'Actions', dataType: DataType.String
    },],
    rowKeyField: '_id',
    sortingMode: SortingMode.Single,
    singleAction: loadData(),
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: 10,
      pageSizes: [5, 10, 15],
      position: PagingPosition.Bottom
    },
  };

  const stationsTablePropsInit = {
    columns: [
      {key: 'name', title: 'Name', dataType: DataType.String},
      {key: 'code', title: 'Code', dataType: DataType.String},
      {key: 'actions', title: 'Actions', dataType: DataType.String},
    ],
    rowKeyField: '_id',
    sortingMode: SortingMode.Single,
    singleAction: loadData(),
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: 10,
      pageSizes: [5, 10, 15],
      position: PagingPosition.Bottom
    },
  };

  const waitersTablePropsInit = {
    columns: [{key: 'name', title: 'Name', dataType: DataType.String},
      {key: 'code', title: 'Code', dataType: DataType.String},
      {key: 'actions', title: 'Actions', dataType: DataType.String},],
    rowKeyField: '_id',
    sortingMode: SortingMode.Single,
    singleAction: loadData(),
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: 10,
      pageSizes: [5, 10, 15],
      position: PagingPosition.Bottom
    },
  }

  useEffect(() => {
    setLoading(true);

    const fetchAllData = async () => {
      try {
        const res = await getLoggedInUserRestaurants();
        const restaurants = res.data.data;
        const restaurant = restaurants.find(r => r._id === restaurantId);

        if (!restaurant) {
          logout();
          return;
        }

        setCurrentRestaurant(restaurant);

        const categoriesRes = await getRestaurantCategories(restaurant._id);
        const categories = categoriesRes.data.data;
        setCategories(categories);
        await categoriesDispatch(updateData(categories));

        const waiters = await getRestaurantWaiters(restaurant._id);
        setWaiters(waiters.data.data);
        await waitersDispatch(updateData(waiters.data.data));

        const tables = await getRestaurantTables(restaurant._id);
        setTables(tables.data.data);
        await tablesDispatch(updateData(tables.data.data));

        const stations = await getRestaurantTerminals(restaurant._id);
        setStations(stations.data.data);
        await stationsDispatch(updateData(stations.data.data));

        const itemPromises = categories.map(category =>
          getCategoryItems(restaurant._id, category._id)
            .then(res => {
              const categoryItems = res.data.data;
              categoryItems.forEach(p => (p.categoryName = category.name));
              return categoryItems;
            })
        );

        const itemResults = await Promise.all(itemPromises);
        const items = itemResults.flat();
        items.forEach(i => {
          if (!i.image) {
            i.image = "https://st3.depositphotos.com/17828278/33150/v/450/depositphotos_331503262-stock-illustration-no-image-vector-symbol-missing.jpg";
          }
        })
        setItems(items);
        await itemsDispatch(updateData(items));
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);


  const [itemsTableProps, changeItemsTableProps] = useState(itemsTablePropsInit);
  const itemsDispatch = async (action) => {
    changeItemsTableProps((prevState) => kaReducer(prevState, action));
  };

  const [categoriesTableProps, changeCategoriesTableProps] = useState(categoriesTablePropsInit);
  const categoriesDispatch = async (action) => {
    changeCategoriesTableProps((prevState) => kaReducer(prevState, action));
  };

  const [tablesTableProps, changeTablesTableProps] = useState(tablesTablePropsInit);
  const tablesDispatch = async (action) => {
    changeTablesTableProps((prevState) => kaReducer(prevState, action));
  };

  const [stationsTableProps, changeStationsTableProps] = useState(stationsTablePropsInit);
  const stationsDispatch = async (action) => {
    changeStationsTableProps((prevState) => kaReducer(prevState, action));
  };

  const [waitersTableProps, changeWaitersTableProps] = useState(waitersTablePropsInit);
  const waitersDispatch = async (action) => {
    changeWaitersTableProps((prevState) => kaReducer(prevState, action));
  };

  const deleteItem = (categoryId, itemId) => {
    if (!window.confirm("Are you sure you want to proceed?")) {
      return;
    }
    axios({
      method: "delete",
      url: `https://neomenu-backend.fly.dev/restaurants/${restaurantId}/categories/${categoryId}/items`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: itemId
      }
    })
      .then(async res => {
        let remainingItems = items.filter(p => p._id !== itemId);
        setItems(remainingItems);
        itemsDispatch(updateData(remainingItems));
      })
      .catch(e => console.log(JSON.stringify(e)))
  }

  const deleteCategory = (categoryId) => {
    if (!window.confirm("Deleting this category will delete all its associated items. It will also delete stations that only depend on it. Are you sure you want to proceed?")) {
      return;
    }
    axios({
      method: "delete", url: `https://neomenu-backend.fly.dev/restaurants/${restaurantId}/categories`, headers: {
        Authorization: `Bearer ${token}`,
      }, data: {
        id: categoryId
      }
    })
      .then(async res => {
        let remainingCategories = categories.filter(c => c._id !== categoryId);
        setCategories(remainingCategories);
        getRestaurantTerminals(currentRestaurant._id)
          .then(async stations => {
            setStations(stations.data.data);
            await stationsDispatch(updateData(stations.data.data));
          })
        await categoriesDispatch(updateData(remainingCategories));
      })
      .catch(e => console.log(JSON.stringify(e)))
  }

  const deleteStation = (stationId) => {
    if (!window.confirm("Are you sure you want to proceed?")) {
      return;
    }
    axios({
      method: "delete", url: `https://neomenu-backend.fly.dev/restaurants/${restaurantId}/stations`, headers: {
        Authorization: `Bearer ${token}`,
      }, data: {
        id: stationId
      }
    })
      .then(async res => {
        let remainingStations = stations.filter(t => t._id !== stationId);
        setStations(remainingStations);
        await stationsDispatch(updateData(remainingStations))
      })
      .catch(e => console.log(JSON.stringify(e)))
  }

  const deleteTable = (tableId) => {
    if (!window.confirm("Are you sure you want to proceed?")) {
      return;
    }
    axios({
      method: "delete", url: `https://neomenu-backend.fly.dev/restaurants/${restaurantId}/tables`, headers: {
        Authorization: `Bearer ${token}`,
      }, data: {
        id: tableId
      }
    })
      .then(async res => {
        let remainingTables = tables.filter(t => t._id !== tableId);
        setTables(remainingTables);
        await tablesDispatch(updateData(remainingTables));
      })
      .catch(e => console.log(JSON.stringify(e)))
  }

  const deleteWaiter = (waiterId) => {
    if (!window.confirm("Are you sure you want to proceed?")) {
      return;
    }
    axios({
      method: "delete", url: `https://neomenu-backend.fly.dev/restaurants/${restaurantId}/waiters`, headers: {
        Authorization: `Bearer ${token}`,
      }, data: {
        id: waiterId
      }
    })
      .then(async res => {
        let remainingWaiters = waiters.filter(w => w._id !== waiterId);
        setWaiters(remainingWaiters);
        await waitersDispatch(updateData(remainingWaiters));
      })
      .catch(e => console.log(JSON.stringify(e)))
  }

  const options = ["Categories", "Items", "Stations", "Tables", "Waiters"];
  const [currentOption, setCurrentOption] = useState(options[0]);
  const dropdownButton = <DropdownButton currentOption={currentOption} options={options}
                                         setCurrentOption={setCurrentOption}/>

  const categoriesTable = <CategoriesTable
    dropdownButton={dropdownButton}
    tableProps={categoriesTableProps}
    deleteCategory={deleteCategory}
    showCreateModal={handleShowNewCategoryModal}
    dispatch={categoriesDispatch}
    length={categories.length}
    loading={loading}
  />

  const itemsTable = <ItemsTable
    dropdownButton={dropdownButton}
    tableProps={itemsTableProps}
    deleteItem={deleteItem}
    showCreateModal={handleShowNewItemModal}
    dispatch={itemsDispatch}
    disabled={categories.length === 0}
    length={items.length}
    loading={loading}
  />

  const tablesTable = <TablesTable
    dropdownButton={dropdownButton}
    tableProps={tablesTableProps}
    deleteTable={deleteTable}
    showCreateModal={handleShowNewTableModal}
    dispatch={tablesDispatch}
    length={tables.length}
    loading={loading}
  />

  const stationsTable = <StationsTable
    dropdownButton={dropdownButton}
    tableProps={stationsTableProps}
    deleteTerminal={deleteStation}
    showCreateModal={handleShowNewTerminalModal}
    dispatch={stationsDispatch}
    disabled={categories.length === 0}
    length={stations.length}
    categories={categories}
    loading={loading}
  />

  const waitersTable = <WaitersTable
    dropdownButton={dropdownButton}
    tableProps={waitersTableProps}
    deleteWaiter={deleteWaiter}
    showCreateModal={handleShowNewWaiterModal}
    dispatch={waitersDispatch}
    length={waiters.length}
    loading={loading}
  />

  const renderTable = () => {
    switch (currentOption) {
      case "Items":
        return itemsTable;
      case "Categories":
        return categoriesTable;
      case "Tables":
        return tablesTable;
      case "Stations":
        return stationsTable;
      case "Waiters":
        return waitersTable;
    }
  }

  return (<Container className="pt-4">
    <Row>
      <Col>
        {renderTable()}
      </Col>
    </Row>
    <CreateCategoryForm show={showNewCategoryModal} handleClose={handleCloseNewCategoryModal}
                        restaurantId={restaurantId} addCategory={addCategory}/>
    <CreateItemForm show={showNewItemModal} handleClose={handleCloseNewItemModal} restaurantId={restaurantId}
                    categories={categories} addItem={addItem}/>
    <CreateWaiterForm show={showNewWaiterModal} handleClose={handleCloseNewWaiterModal} restaurantId={restaurantId}
                      addWaiter={addWaiter}/>
    <CreateStationForm show={showNewTerminalModal} handleClose={handleCloseNewTerminalModal}
                       restaurantId={restaurantId}
                       categories={categories} addTerminal={addStation}/>
    <CreateTableForm show={showNewTableModal} handleClose={handleCloseNewTableModal} restaurantId={restaurantId}
                     addTable={addTable}/>
  </Container>);
}
